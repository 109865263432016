import ApiService from "../../services/api.service";

/**
 * Verify the the text is valid language
 */
export const verifyLanguage = async (headers) =>{
    let result;
    try{
        result = await ApiService.verifyLanguage(headers);

    }catch(err){
        console.error("Error occurred while verifyLanguage -- ", err);
    }
    return result;
}